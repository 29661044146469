<template>
  <div id="main">
    <h2 class="title">{{ News.title }}</h2>
    <p>发布时间：{{ new Date(News.creatTime).format("yyyy-MM-dd") }}</p>
    <el-divider></el-divider>
    <div class=" ql-snow">
<!--      <div class="ql-editor">-->
        <div class="ql-editor" v-html="News.content" v-loading="loading"></div>
        <!--        <div v-html="html"></div>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
export default {
  name: "ServicesCaseContent",
  data() {
    return {
      News: null,
      loading: true
    }
  },
  created() {
    this.News = this.$route.query.News

    if (this.News.title === undefined) {
      this.News = JSON.parse(window.sessionStorage.getItem('serviceCase'))
      this.getNesById(this.News.id)
    } else {
      this.getNesById(this.News.id)
      window.sessionStorage.setItem("serviceCase", JSON.stringify(this.News))
    }
  },
  mounted() {

  },
  methods: {

    getNesById(id) {
      this.$http.get('/news/' + id).then(({data}) => {
        if (data.code === 200) {
          this.News = data.obj;
          this.loading = false
          return
        }
        this.$message.error("加载失败了")
      }).catch((error) => {
        this.$message.error("加载失败了")
      })
    }
  }
}
</script>

<style scoped>
#main {
  width: 1200px;
  margin: 110px auto 0 auto;
}


h2 {
  margin: 45px auto 20px auto;
  text-align: center;
}

.el-divider {
  margin-top: 0;
}

.content {
  min-height: 200px;
}

</style>
