<template>
  <div id="main">
    <div class="classify"><span class="VerticalLine"></span><span class="content">案例分类</span>
      <el-divider></el-divider>
      <div class="classify-content">
        <el-radio-group v-model="defaultCaseType" size="mini" @change="radioBtn">
          <el-radio-button label="全部案例"></el-radio-button>
          <el-radio-button v-for="item in CaseLabelList" :label="item.caseType"></el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="case-list" v-if="caseTypeNewsList.length" v-loading="loading">
      <el-card class="case-card" shadow="hover" v-for="item in caseTypeNewsList" >
        <router-link :to="{ path:'/servicesCase/Content',query:{News:item}}">
          <div class="case-card-img"><img :src="item.coverUrl" alt=""></div>
          <div class="case-card-title">{{ item.title }}</div>
        </router-link>
      </el-card>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[8, 16, 32, 64]"
          :page-size="page.currentPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <div style="width: 100%;" v-else>
      <el-empty description="暂时没有数据 . . ."></el-empty>
    </div>

  </div>
</template>

<script>
export default {
  name: "ServicesCase",
  data() {
    return {
      loading: true,
      total: 0,
      defaultCaseType: '全部案例',
      CaseLabelList: [],
      caseTypeNewsList: [],
      page: {
        caseType: '',
        currentPage: 1,
        currentPageSize: 8
      }
    }
  },
  methods: {
    /**
     *
     *  获取服务案例类别
     **/
    getCaseType() {
      this.$http.get('/news/selectCaseType').then(({data}) => {
        console.log(data)
        if (data.code === 200) {
          this.CaseLabelList = data.obj
        }
      })
    },
    /**
     *
     *  获取案例数据
     **/
    getCaseData() {
      this.$http.post('/news/servicesCase', this.page).then(({data}) => {
        if (data.code === 200) {
          this.total = data.obj.total
          this.caseTypeNewsList = data.obj.data
          this.loading=false
        }
      })
    },
    radioBtn(label) {
      this.loading=true
      if (label === "全部案例") {
        this.page.caseType = null
        return this.getCaseData()
      }
      this.page.caseType = label
      this.page.currentPage = 1
      this.getCaseData()
    },
    /**
     *
     *  页数改变事件
     **/
    handleCurrentChange(currentPage) {
      this.loading=true
      this.page.currentPage = currentPage
      this.getCaseData()
    },
    /**
     *
     *  页面大小改变事件
     **/
    handleSizeChange(currentPageSize) {
      this.loading=true
      this.page.currentPageSize = currentPageSize
      this.getCaseData()
    }
  },
  mounted() {
    this.getCaseType()
    this.getCaseData()
  }
}
</script>

<style scoped>
#main {
  width: 1200px;
  margin: 110px auto 0 auto;
}

.classify {
  margin-top: 60px;
  color: #409EFF;
}

.classify-content {
  margin-top: 10px;
  text-align: center;
}

.classify .classify-content {
  text-align: left;
}

.content {
  display: inline-block;
  vertical-align: top;
}

.VerticalLine {
  display: inline-block;
  width: 3px;
  height: 20px;
  border-radius: 5px;
  margin-right: 10px;
  vertical-align: center;
}

.classify .VerticalLine {
  background-color: #409EFF;
}

.el-radio-button {
  margin-bottom: 10px;
}

.el-divider {
  margin: 0;
}

/deep/ .el-radio-button__inner {
  width: 100px;
  margin-right: 20px;
  border-radius: 0px !important;
  border: 1px #E9E9EB solid;
}

.case-card {
  padding: 0;
  display: inline-block;
  width: 285px;
  height: 250px;
  margin: 20px 20px 0 0;
  cursor: pointer;
}

.case-list > :nth-child(4n) {
  margin-right: 0;
}

/deep/ .el-card__body {
  padding: 0;
}

.case-card-img {
  overflow: hidden;
}

.case-card-img img {
  width: 100%;
  height: 200px;
  transition: all 0.6s;

}

.case-card:hover img {
  transform: scale(1.4);
}

.case-card-title {
  margin-top: 5px;
  padding: 0 20px 0 20px;
}

.case-card:hover .case-card-title {
  color: #E6A23C;
}
.el-pagination{
  margin-bottom: 40px;
}


</style>